<template>
  <div class="maxBox">
    <!-- 顶部通用 -->
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex="activeIndex" />
      </div>
    </div>
    <!-- 新闻中心 -->
    <div class="boxContentNews">
      <div class="newsCenter">
        <!-- 导航条区域 -->
        <div class="newsBar">
          <div class="curror" @click="goLink('/')">首页</div>
          /
          <div>全部新闻</div>
        </div>
        <!-- 新闻主体区域 -->
        <div class="NewSubject">
          <!-- 左侧文字区域 -->
          <div class="NewSubjectLeft">
            <div class="NewSubjectItem" v-for="item in newsArray" :key="item.id" @click="goNews(item.id)">
              <div class="NewSubjectItemLeft">
                <img :src="item.cover" alt="" />
              </div>
              <div class="NewSubjectItemContent">
                <div class="NewSubjectItemContentTitle">
                 {{ item.title }}
                </div>
                <div class="NewSubjectItemContentYear">{{ utils.formatDate(item.createTime) }}</div>
              </div>
              <div class="NewSubjectItemArrow">
                <img src="../../assets/news/news_list_more@2x.png" alt="" />
              </div>
            </div>
            <!-- 切换页码 -->
            <div class="changePage">
              <div class="block">
                <el-pagination
                  layout="prev, pager, next"
                  :page-size="page.pageSize"
                  @current-change="currentChange"
                  :total="page.total">
                </el-pagination>
              </div>
            </div>
          </div>
          <!-- 右侧图文区域 -->
          <div class="NewSubjectRight">
            <div class="solution">
              <div class="solutionTitle" @click="toSolution">
                解决方案
              </div>
              <div @click="goLink('solution')" class="curr-point">
                <div class="solutionContent">
                  <img src="../../assets/homePageImg/two-2.png" alt="" />
                </div>
                <div class="solutionSolve">
                  <div class="solutionSolveLeft">代客交易解决方案</div>
                  <div class="solutionSolveRight">
                    <img src="../../assets/news/recommend_more@2x.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="platformServices">
              <div class="platformServicesTitle">平台服务</div>
              <div @click="goLink('financialRegion')" class="curr-point">
                <div class="platformServicesContent">
                  <img
                          src="../../assets/homePageImg/platform_ser_qkl_01@2x.png"
                          alt=""
                  />
                </div>
                <div class="solutionSolve">
                  <div class="solutionSolveLeft">区块链金融服务平台</div>
                  <div class="solutionSolveRight">
                    <img src="../../assets/news/recommend_more@2x.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部公共区域 -->
    <div class="boxContentFooter">
      <FooterAll />
    </div>
  </div>
</template>

<script>
import Navbar from '../../components/public/NavBar'
import FooterAll from '../../components/public/footerAll'
import { getNewsList } from '@/api/index'
export default {
  data() {
    return {
      activeIndex: '10',
      newsArray: [],
      page: {
        pageNumber: 1,
        pageSize: 5,
        sort: 'creatTime',
        total: 0
      }
    }
  },
  components: {
    Navbar,
    FooterAll,
  },
  mounted() {
    this.newsList()
  },
  methods: {
    goLink(hash) {
      this.$router.push(hash)
    },
    currentChange(v) {
      this.page.pageNumber = v
      this.newsList()
    },
    newsList() {
      getNewsList({
        ...this.page
      }).then(res => {
        if (res.data.success) {
          this.newsArray = res.data.result.content
          this.page.total = res.data.result.totalElements
        }
      })
    },
    goNews(id) {
      this.$router.push({ path: '/news', query: { newsId: id}})
    },
     toSolution() {
      this.$router.push({
        path: '/solution',
      })
    }
  }
}
</script>
<style>
  .el-pagination .btn-next, .el-pagination .btn-prev,.el-dialog, .el-pager li,.el-pagination button:disabled{
    background: rgba(0,0,0,0);
  }
  .el-pager li.active{
    font-size: 14px;
    font-weight: 400;
    color: #0F4790;
  }
  .el-pager li{
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    padding: 0 10px;
    min-width: auto;
  }
  .el-pager li.btn-quicknext, .el-pager li.btn-quickprev{
    color: #999999;
  }
</style>
<style lang="scss" scoped>
  .curr-point{
    cursor: pointer;
    &:hover {
      .solutionSolveLeft {
        color: #0F4994!important;
      }
    }
  }
@import './news.scss';
.NewSubjectLeft{
  width: 100%;
  margin-top: -24px;
}
/*.NewSubjectLeft .NewSubjectItem:nth-of-type(1) {*/
/*  padding-top: 0;*/
/*}*/
.NewSubjectItem {
  display: flex;
  width: 820px;
  border-bottom: 1px solid #eee;
  padding-bottom: 24px;
  padding-top: 24px;
  cursor: pointer;
  position: relative;
  z-index: 10;
  &:hover {
    .NewSubjectItemContentTitle{
      color: #0F4994;
      font-weight: 600;
    }
    &::after {
      content: '';
      position: absolute;
      width: 853px;
      height: 144px;
      left: -16px;
      top: 8px;
      background: #fff;
      box-shadow: 0px 3px 20px 0px rgb(0 0 0 / 9%);
      z-index: -1;
    }
  }
  .NewSubjectItemLeft {
    width: 200px;
    height: 112px;
    > img {
      @extend .imgFill;
    }
  }
  .NewSubjectItemContent {
    padding-left: 20px;
    width: 500px;
    .NewSubjectItemContentTitle {
      padding-bottom: 42px;
      height: 50px;
      font-size: 18px;
      font-weight: 600;
      color: #181818;
      line-height: 25px;
    }
    .NewSubjectItemContentYear {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  .NewSubjectItemArrow {
    width: 32px;
    height: 32px;
    padding-top: 45px;
    margin-left: auto;
    > img {
      @extend .imgFill;
    }
  }
}
.changePage {
  display: flex;
  justify-content: center;
  padding: 34px 0 48px 0;
  text-align: center;
  // margin: 0 auto;
  .pagePre,
  .pageNext {
    padding: 3px 0;
    width: 14px;
    height: 14px;
    > img {
      display: block;
      @extend .imgFill;
    }
  }
  .pagePre {
    padding-right: 12px;
  }
  .pageNext {
    // padding-left: -7px;
    margin-left: -8px;
  }
  .pageNumber {
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    > div {
      padding-right: 20px;
    }
  }
}
</style>
